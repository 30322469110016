<template>
  <div class="container">
    <div class="cui__utils__heading">
      <strong>Create User</strong>
    </div>
    <b-card no-body>
      <b-card-header class="bg-secondary text-white" v-if="(isAdmin || isAccountAdmin)">
        <b-form-group>
          <label for="customerGroup">Choose client</label>
          <b-form-select
            id="customerGroup"
            v-model="selectedCustomerGroupId"
            :options="customerGroupOptions"
            size="lg"
            @change="fetchSelectedCustomerGroup"></b-form-select>
        </b-form-group>
      </b-card-header>
      <b-card-body>
        <EditUserProfileForm
          v-if="customerGroup"
          :customerGroup="customerGroup"
          :errors="errors"
          :user="user"
          :isSSO="isSSO"
          createFlow=true
          @submit="onSubmit" />
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import EditUserProfileForm from '@/components/users/editUserProfileForm.vue';
import generateNonce from '@/util/generateNonce';

export default {
  components: {
    EditUserProfileForm
  },
  data() {
    return {
      user: null,
      errors: [],
      newUserId: '',
      tempPassword: '',
      selectedCustomerGroupId: null,
      selectedCustomerGroup: null,
      isSSO: false,
    }
  },
  computed: {
    ...mapGetters('ping', [
      'isAdmin',
      'isAccountAdmin',
      'isClientAdmin',
      'IDARoleID',
      'currentUserIDARoleAssignmentPopulationIds',
      'manageableAccounts',
    ]),
    ...mapGetters('ocapi', [
      'getClientConfig'
    ]),
    ...mapState('ping', [
      'environmentId'
    ]),
    ...mapState('ocapi', {
      actorCustomerGroup: 'customerGroup',
    }),
    ...mapState('ocapi', [
      'allCustomerGroups'
    ]),
    ...mapState('admin', [
      'globalClientSelected'
    ]),
    customerGroup() {
      if (this.isAdmin || this.isAccountAdmin) {
        return this.selectedCustomerGroup;
      }
      if (this.isClientAdmin) {
        return this.actorCustomerGroup;
      }
      return null;
    },
    customerGroupOptions() {
      return (this.manageableAccounts?.length ? this.manageableAccounts : this.allCustomerGroups)
        .filter(item => item.id !== 'All')
        .sort((a, b) => {
          const textA = a.name.toUpperCase();
          const textB = b.name.toUpperCase();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        })
        .map(item => ({ value: item.id, text: item.name }))
    }
  },
  methods: {
    ...mapActions('ping', [
      'createUser',
      'createUserIDARole',
      'fetchCurrentUserRoleAssignments',
      'fetchRoles',
      'fetchPopulations',
      'passwordReset'
    ]),
    ...mapActions('ocapi', [
      'fetchCustomerGroup'
    ]),
    ...mapActions('admin', ['sendNewUserEmail']),
    ...mapMutations('admin', [
      'startLoading',
      'stopLoading',
      'setGlobalClientSelected'
    ]),
    ...mapMutations('ping', [
      'setNewUserId',
      'setNewUserTemporaryPassword'
    ]),
    fetchSelectedCustomerGroup() {
      if (this.selectedCustomerGroupId === this.globalClientSelected) {
        this.startLoading();
        this.fetchCustomerGroup(this.selectedCustomerGroupId)
          .then(response => {
            this.selectedCustomerGroup = response.data;
            this.stopLoading();
          })
          .catch(error => {
            console.error(error);
            this.stopLoading();
          });
      } else {
        this.setGlobalClientSelected(this.selectedCustomerGroupId)
      }
    },
    onSubmit(data, isSSO) {
      this.startLoading();
      this.errors = [];
      const newData = {
        ...data,
        population: {
          id: this.customerGroup.id
        }
      };
      if (isSSO) {
        newData.password = {
          value: 'ZH30c5*iY^T352M1s87o',
          forceChange: false
        }
      }

      this.createUser({
        data: newData
      })
        .then(response => {
          this.newUserId = response.data.id;
          // if new user is admin or accountadmin
          // create environment IDA Role
          if (data.profile.role === 'admin' || data.profile.role === 'accountadmin') {
            if (this.isAdmin) {
              return this.createUserIDARole({
                userId: this.newUserId,
                type: 'environment',
                id: this.environmentId
              });
            }
          }
          // if new user is clientadmin
          // or new user is reporting
          // create population IDA role
          if (
            data.profile.role === 'clientadmin' ||
            data.profile.role === 'reporting'
          ) {
            return this.createUserIDARole({
              userId: this.newUserId,
              type: 'population',
              id: this.customerGroup.id
            });
          }
          // else
          return Promise.resolve();
        })
        .then(() => {
          if (!isSSO) {
            const temporaryPassword = generateNonce(8);
            this.tempPassword = temporaryPassword;
            this.setNewUserTemporaryPassword(temporaryPassword);
            return this.passwordReset({
              userId: this.newUserId,
              password: temporaryPassword
            });
          }
          return Promise.resolve();
        })
        .then(() => {
          this.setNewUserId(this.newUserId);
          if (!isSSO) {
            this.sendNewUserEmail({
              email: data.email,
              firstName: data.name.given,
              lastName: data.name.family,
              tempPassword: this.tempPassword,
              url: 'link.hallmarkbusiness.com',
              clientName: this.customerGroup.description,
            });
          }
          this.stopLoading();
          this.$router.push(`/users/${this.newUserId}`)
        })
        .catch(({ response }) => {
          console.log(response);
          const errors = response?.data?.details;
          if (errors.length) {
            this.errors = errors;
          } else {
            this.errors = [];
          }
          this.stopLoading();
        });
    }
  },
  mounted() {
    if (this.isAdmin || this.isAccountAdmin || this.isClientAdmin) {
      this.startLoading();
      const setupRequests = [
        this.fetchCurrentUserRoleAssignments(),
        this.fetchRoles(),
        this.fetchPopulations()
      ];
      if (this.isAdmin || this.isAccountAdmin) {
        if (this.globalClientSelected !== 'All') {
          this.selectedCustomerGroupId = this.globalClientSelected
        } else {
          this.selectedCustomerGroupId = this.customerGroupOptions[0].value;
        }
        setupRequests.push(this.fetchSelectedCustomerGroup());
      }
      Promise.all([
        ...setupRequests
      ])
        .then(() => {
          this.stopLoading();
        })
        .catch(error => {
          console.error(error);
          this.stopLoading();
        });
    }
  },
  watch: {
    customerGroup() {
      const clientConfig = this.getClientConfig(this.customerGroup);
      this.isSSO = clientConfig && 'defaultNewUserTypeSSO' in clientConfig ? clientConfig.defaultNewUserTypeSSO : false;
    },
    globalClientSelected() {
      if (this.globalClientSelected !== 'All') {
        this.selectedCustomerGroupId = this.globalClientSelected
        this.fetchSelectedCustomerGroup()
      }
    }
  }
}
</script>
